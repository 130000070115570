import initSliders, { makeProdCarouselSwiper } from "./components/slider";
import { hidePreloader, showPreloader } from "./components/preloader";
import { getFetchOptions } from "./utils";
import { refreshLazyImages } from "./components/lazy";
import { StatusClassNames } from "./types/StatusClassNames";
initSliders();
init();
function init() {
    const wrapper = document.getElementById('prod-carousel');
    const container = wrapper === null || wrapper === void 0 ? void 0 : wrapper.querySelector('.block__body');
    const tabs = wrapper === null || wrapper === void 0 ? void 0 : wrapper.querySelector('.tabs');
    if (!container || !wrapper || !tabs) {
        return;
    }
    tabs.querySelectorAll('.tabs__link').forEach(button => {
        button.addEventListener('click', () => {
            tabs.querySelectorAll(`.tabs__link.${StatusClassNames.active}`)
                .forEach(el => el.classList.remove(StatusClassNames.active));
            button.classList.add(StatusClassNames.active);
            showPreloader(wrapper);
            fetch('/ajax/slider', getFetchOptions({
                type: button.dataset.type,
                elementId: wrapper.dataset.id,
            }, true))
                .then(response => response.text())
                .then(html => {
                const existingCarousel = document.getElementById('prod-swiper');
                if (existingCarousel) {
                    existingCarousel.swiper.destroy();
                }
                container.innerHTML = html;
                refreshLazyImages(container);
                makeProdCarouselSwiper();
            })
                .finally(() => hidePreloader(true));
        });
    });
}
