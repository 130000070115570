import { loadImage } from "../lazy";
const IMG_SELECTOR = 'picture.lazy:not(.img--loaded) img:not(.loading)';
export default function LazyImages({ swiper, on, off }) {
    on('init', swiper => {
        // задержка нужна, чтобы не происходило отмены загрузки картинок
        setTimeout(() => check(swiper), 100);
    });
    on('slideChangeTransitionStart', check);
    on('slideChangeTransitionEnd', check);
    function check(swiper) {
        const slidesPerView = swiper.params.slidesPerView === 'auto' ? Math.round(swiper.width / swiper['slidesSizesGrid'][0]) : swiper.params.slidesPerView;
        const activeIndex = swiper.activeIndex;
        const totalSlides = getTotalSlidesNumber();
        if (!isSwiperVisible(swiper)) {
            return;
        }
        let updated = preloadImages(activeIndex + Math.floor(slidesPerView), activeIndex + Math.ceil(slidesPerView * 2));
        if (!swiper.loopedSlides) {
            return;
        }
        const startIndex = (swiper.realIndex < slidesPerView ? totalSlides + swiper.realIndex - slidesPerView : swiper.realIndex - slidesPerView);
        for (let i = startIndex; i < startIndex + slidesPerView; i++) {
            for (const image of swiper.wrapperEl.querySelectorAll(`.${swiper.params.slideClass}[data-swiper-slide-index="${i}"] ${IMG_SELECTOR}`)) {
                if (preloadImage(image)) {
                    updated = true;
                }
            }
        }
        if (updated) {
            return;
        }
        if (swiper.wrapperEl.querySelectorAll(IMG_SELECTOR).length) {
            return;
        }
        off('init', check);
        off('slideChangeTransitionStart', check);
        off('slideChangeTransitionEnd', check);
    }
    function preloadImages(start, end) {
        let updated = false;
        for (let i = start; i < end; i++) {
            if (!swiper.slides[i]) {
                break;
            }
            if (preloadImage(swiper.slides[i].querySelector(IMG_SELECTOR))) {
                updated = true;
            }
        }
        return updated;
    }
    function preloadImage(image) {
        if (!image) {
            return false;
        }
        loadImage(image);
        return true;
    }
    function getTotalSlidesNumber() {
        if (!swiper.loopedSlides) {
            return swiper.slides.length;
        }
        return swiper.wrapperEl.querySelectorAll(swiper.params.slideClass).length;
    }
    function isSwiperVisible(swiper) {
        const rect = swiper.wrapperEl.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        return rect.bottom > 0 && rect.top < windowHeight * 1.5;
    }
}
